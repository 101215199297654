.confirm_to_link_modal__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.confirm_to_link_modal__buttons button {
    margin: 5px 0;
}

body .modal + .modal-backdrop {
    z-index: 1065;
}

body .modal.confirm_modal {
    z-index: 1070;
}