.loginForm__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.loginForm {
  border: 2px solid #aaa;
  border-radius: 10px;
  max-width: 330px;
  padding: 20px;
}

.loginForm__input {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginForm__input input {
  border-radius: 0;
  width: 100%;
}

.loginForm__button {
  margin-top: 20px;
  width: 100%;
  border: 1px solid dodgerblue;
  border-radius: 0;
  color: dodgerblue;
}

.loginForm__button:hover {
  border: 1px solid rgb(75, 75, 75);
  color: rgb(75, 75, 75);
  background-color: #fff;
}

.loginForm img {
  width: 70%;
}

.disable {
  opacity: .65;
  pointer-events: none;
}