.sr-only {
  display: none;
}

.table tbody, .table td, .table tfoot, .table th, .table thead, .table tr {
  border-style: none;
  border-bottom-style: solid;
}

.dropdown .dropdown-menu {
  min-width: 1rem;
  left: 5px;
  top: -150px;
}

.settings-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.settings-bar__left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.settings-bar__left .btn {
  margin: 0 5px;
}

.settings-bar__right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}

.settings-bar__right__search_label {
  position: absolute;
  left: 10px;
  bottom: -15px;
  font-size: 14px;
}

.table .react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: center;
}

@media screen and (max-device-width: 1279px) {

  .table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }

}

@media screen and (max-device-width: 800px) {

  .table .react-bootstrap-table-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 130px;
  }

}