.modal .close {
    padding: 1rem;
    margin: -2rem -1rem -1rem auto;
    background-color: transparent;
    border: 0;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.modal .modal-header .close:hover {
    opacity: 1;
    text-decoration: none;
}

.reports_users_modal__wrapper .table_date_picker_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
}

.reports_users_modal__wrapper .table_date_picker_wrapper .date-picker {
    padding: 0 24px;
}

.react-google-flight-datepicker {
    max-width: 420px;
    min-width: 280px;
}

.reports_users_modal {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: calc(100vh - 105px);
}

.reports_users_modal__users {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-right: 1px solid #dee2e6;
    height: 100%;
}

.reports_users_modal__users__search {
    width: 300px;
    padding: 10px;
}

.reports_users_modal__users__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    width: -webkit-fill-available;
    width: -moz-available;
}

.reports_users_modal__users__list ul {
    list-style: none;
    padding-left: 0;
    width: -webkit-fill-available;
    width: -moz-available;
}

.reports_users_modal__users__list_item {
    padding-left: 10px;
}

.reports_users_modal__users__list_item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.reports_users_modal__users__list_item_selected {
    background-color: #e3f7fd;
}

.reports_users_modal__users__list_item_selected:hover {
    background-color: #e3f7fd;
}

.reports_users_modal__wrapper .modal-body {
    padding: 0;
    overflow-x: hidden;
}

.reports_users_modal__wrapper .modal-header {
    padding: 0 1rem;
}

.balances_user {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.balances_user__service {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.balances_user__service:not(:first-child) {
    margin-left: 30px;
}

.balances_user__service_name {
    font-weight: bold;
}

.reports_users_modal__table {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
}

.balances_user__profile, .balances_user__profile__button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.balances_user__profile__button {
    margin-left: 15px;
}