.service_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0 4px;
}

.service_icon:hover {
    border-radius: 50%;
    background-color: #f0f0f0;
}