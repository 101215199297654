.button-balance {
  position: relative;
}

.button-balance__toast {
  position: absolute;
  right: 80px;
  bottom: 10px;
}

.button-balance__toast__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button-balance  .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  background-color: transparent;
  border: 0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.button-balance .close:hover {
  opacity: 1;
  text-decoration: none;
}