.password_field {
  position: relative;
  width: 100%;
}

.form-control_btn_show_pass {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.view {
  background: url(../../../images/icons/view.svg) 0 0 no-repeat;
}

.no-view {
  background: url(../../../images/icons/no-view.svg) 0 0 no-repeat;
}