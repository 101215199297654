.modal .close {
  padding: 1rem;
  margin: -2rem -1rem -1rem auto;
  background-color: transparent;
  border: 0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.modal .modal-header .close:hover {
  opacity: 1;
  text-decoration: none;
}

.table_date_picker_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-google-flight-datepicker {
  max-width: 420px;
  min-width: 280px;
}